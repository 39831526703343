import React from 'react';
import { withRouter} from 'react-router-dom';

function FooterBar(props) {


  return (
    <footer>
      <div className="bg-dark footer">
        <div className="container">
      <div className="row justify-content-center">
        
        <div className="col-md-12">
        
         Copyright &copy; {(new Date().getFullYear())} <a href="http://www.accountsdeck.com" target="_blank" rel="noopener  noreferrer"> Accountsdeck </a>
          </div>
        
        </div>
          
         
         </div>
         </div>
    </footer>
  );
}

export default  withRouter(FooterBar);