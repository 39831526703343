import { url } from '../config/config'
import axios from 'axios';

class Auth {
    constructor() {
        this.token = JSON.parse(localStorage.getItem("currentUser"));
        this.mobile = localStorage.getItem("mobile");
    }

    isAuthenticated() {
        return this.token != null && new Date().getTime() < this.token.expire;
    }

    getAuthToken(){
        return this.token.token;
    }

    getMobileNo() {
        return this.mobile;
    }

    signOut() {
         // remove user from local storage to log user out
         localStorage.removeItem('currentUser');
         localStorage.removeItem("mobile");
         this.token = null;
    }


    async login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'username': username,
                'password': password
            })
        };

        return fetch(`${url}clinic/login`, requestOptions)
            // .then(handleResponse)
            .then(response => {
                // if(response.text()===''){
                //     const error ='Invalid Username or Password';   
                //     return Promise.reject(error); 
                // }
               return  response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        
                        const error = (data && data.message) || response.statusText;
            
                        return Promise.reject(error);
                    }
            
                    return data;
                });
            })
            
            .then(user => {
                // console.log(user);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.token = user;
                this.mobile = username;
                localStorage.setItem("mobile", username);

                return user;

            });
    }
}

const authenticationService = new Auth();

export default authenticationService;