import React from 'react';
import axios from 'axios';
import { handleResponse } from '../auth/handle-response';
import { url } from '../config/config'
import authenticationService from '../auth/AuthenticationService';
import { Link } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';


class DiagnoseList extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.length > 2) {
      this.combox = JSON.parse(localStorage.getItem("comboBox") == null ? "" : localStorage.getItem("comboBox"));
    } else {
      this.combox = 'All';
    }
    this.state = {
      data: null,
      items: null
    }
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    this.state.group = params.id;
    this.setState({});
    await axios.get(`${url}clinic/diagnose`, {
      params: {
        'key': authenticationService.getAuthToken()
      }
    }).then(handleResponse)
      .then(res => {
        this.setState({
          data: res,
          items: res
        });
      });
    $(document).ready(function () {
      // $(document).on('ready',function (e) {
      var text;
      if (localStorage.length > 2) {
        text = JSON.parse(localStorage.getItem("comboBox") == null ? "" : localStorage.getItem("comboBox"));
      } else {
        text = 'All';
      }
      $("#type").html(text);

      $('table tr').each(function () {
        var Cell = $(this).find('td:eq(5)');
        if (Cell.text() == 'Prepared') {
          $(this).find('#btnDetail').hide();
        } else {
          $(this).find('#btnDelivered').hide();
        }
      });
    });

  }

  allAction() {
    localStorage.setItem('comboBox', JSON.stringify("All"));
    this.combox = JSON.parse(localStorage.getItem("comboBox") == null ? "" : localStorage.getItem("comboBox"));
    this.render();
    window.location.reload(true);
  }

  pendingAction() {
    localStorage.setItem('comboBox', JSON.stringify("Pending"));
    this.combox = JSON.parse(localStorage.getItem("comboBox") == null ? "" : localStorage.getItem("comboBox"));
    this.render();
    window.location.reload(true);
  }

  preparedAction() {
    localStorage.setItem('comboBox', JSON.stringify("Prepared"));
    this.combox = JSON.parse(localStorage.getItem("comboBox") == null ? "" : localStorage.getItem("comboBox"));
    this.render();
    window.location.reload(true);
  }


  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="container">
              <ol className="breadcrumb breadcrumb-arrow">
                <li><Link to={`/`}> Diagnose </Link> </li>
              </ol>
            </div>
          </div></div>

        <div className="container">

          <div className="row">

            <div>

              {this.state.data === null && <p>Loading Patients...</p>}

              {this.state.data &&
                <div class="table-responsive">
                  Total Entries : {this.state.data.length}

                  <table class="table table-striped">

                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Token</th>
                        <th>Patient Detail</th>
                        <th>Doctor</th>
                        <th>Prepare Time</th>
                        <th>Status</th>
                        <div class="btn-group">
                          <button type="button" id="type" class="btn btn-secondary "> </button>
                          <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                            id="dropdownMenuButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu">

                            <a class="dropdown-item" onClick={this.allAction.bind(this)}>All</a>
                            <a class="dropdown-item" onClick={this.pendingAction.bind(this)} >Pending</a>
                            <a class="dropdown-item" onClick={this.preparedAction.bind(this)} >Prepared</a>
                          </div>
                        </div>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.data.filter(e => this.combox == ('All') || e.male == (this.combox)).map(item => (
                          <tr key={item.id} >
                            <td class="time">{new Date(item.date).toLocaleString()}</td>
                            <td>{item.token}</td>
                            <td> {
                              <React.Fragment key={`${'{ ' + item.address + ' } ' + item.name}`}>
                                {'{ ' + item.address + ' } ' + item.name}
                                <br />
                                {item.remarks}
                              </React.Fragment>
                            }</td>
                            <td>{item.doctor}</td>
                            <td class="time">{(item.prep_time == undefined ? "" : new Date(item.prep_time).toLocaleString())} </td>
                            <td>{item.male}</td>
                            <Link to={{ pathname: `/clinic/diagnosedetail/${item.id}` }}> <button
                              className="btn btn-info" id="btnDetail"> Detail  </button> </Link>

                            <button className="btn btn-success" id="btnDelivered"
                              onClick={(e) => this.delivered(item.id)} > Delivered  </button>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        </div></div>
    );
  }

  formatDate(val) {
    if(val != undefined) 
    console.log(new Intl.DateTimeFormat('en-US', {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit'
    }).format(val));
  }

  async delivered(val) {
    console.log(val);
    await axios.post(`${url}clinic/diagnose/delivered/` + val, null, {
      params: {
        'key': authenticationService.getAuthToken()
      }
    }).then(handleResponse)
      .then(res => {
        console.log(res);
        if (res == null) {
          return;
        }
        store.addNotification({
          title: "Success!",
          message: "Status Delivered Update Success",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      }, error => {
        store.addNotification({
          title: "Error!",
          message: error.response.data.error || "Error on Update Record",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 400,
            onScreen: true
          }
        });
      });
  }
}

export default DiagnoseList;