import React from 'react';
import axios from 'axios';
import { handleResponse } from '../auth/handle-response';
import { url } from '../config/config'
import authenticationService from '../auth/AuthenticationService';
import { Link } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'react-notifications-component/dist/theme.css'


class AppointmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            items: null
        }
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        this.state.group = params.id;
        this.setState({});
        await axios.get(`${url}clinic/appointment`, {
            params: {
                'key': authenticationService.getAuthToken()
            }
        }).then(handleResponse)
            .then(res => {
                this.setState({
                    data: res,
                    items: res
                });
            });
    }


    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="container">
                        {/* <marquee style={{ color: 'black', fontSize: 'Todays Appointments' }}>Test</marquee> */}
                            <ol className="breadcrumb breadcrumb-arrow">
                                <li><Link to={`/`}> Appointments </Link> </li>
                            </ol>
                        </div>
                    </div></div>

                <div className="container">

                    <div className="row">
                        <div>
                            {this.state.data === null && <p>Loading Appointments...</p>}
                            {this.state.data &&
                                <div class="table-responsive">
                                    Total Entries : {this.state.data.length}

                                    <table class="table table-striped">

                                        <thead>
                                            <tr>
                                                <th>Date </th>
                                                <th>Time</th>
                                                <th>Token </th>
                                                <th>Patient Detail</th>
                                                <th>Fee</th>
                                                <th>Doctor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map(item => (
                                                    <tr key={item.id} >
                                                        <td class="date">{item.dt}</td>
                                                        <td>{item.time} </td>
                                                        <td>{(item.token_no == undefined ? "" : item.token_no)}</td>
                                                        <td>{(item.adminss_no == undefined ? "" : " { " + item.adminss_no + " } ")
                                                            + " " + item.patientName +
                                                            (item.mobile == undefined ? "" : " " + item.mobile)}</td>
                                                        <td>{item.fee}</td>
                                                        <td>{item.doctorName}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div></div>
        );
    }


}

export default AppointmentList;