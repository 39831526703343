import React from 'react';
import { withRouter } from 'react-router-dom';
import authenticationService from '../auth/AuthenticationService';
class login  extends  React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
            userName: '',
            password: '',
            erroruserName: false,
            errorpassword: false,
        };

        // redirect to home if already logged in
        if (authenticationService.isAuthenticated()) {
            this.props.history.push('/clinic/diagnose');
        }
        
    }

    render() {
        return (
            <div>
                <input
                    type="text"
                    onBlur={(e) => { this.updateUerName(e.target.value) }}
                    className={'form-control' + (this.state.erroruserName ? ' is-invalid' : '')}
                    placeholder="User Name "
                />
                {this.state.erroruserName ? <div className="invalid-feedback">
                    Please Enter User Name
                 </div> : null}
                <br />
                <input
                    type="text"
                    onBlur={(e) => { this.updatePassword(e.target.value) }}
                    className={'form-control' + (this.state.errorpassword ? ' is-invalid' : '')}
                    placeholder="Password "
                />
                <br />
                    <button className="btn btn-success" onClick={this.login.bind(this)} >Login</button>
            </div>
        )
    }

    
    updateUerName(value) {
        this.setState({
            userName: value,
        });
    }

    updatePassword(value) {
        this.setState({
            password: value
        })
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    login() {
        this.setState({
            erroruserName: false,
            errorpassword: false
        });
            authenticationService.login(this.state.userName, this.state.password)
                .then(
                    user => {
                        const { from } = this.props.location.state 
                        || { from: { pathname: "/clinic/diagnose" } };
                        this.props.history.push(from);
                    },
                    error => {
                        if (error === 'error.http.401') {
                            this.setState({
                                erroruserName: 'Invalid User Name or Password',
                                disabled: false,
                            });
                        }
                    }
                );
        
        // this.props.history.push('/'+this.props.location);

    }

    }

export default withRouter(login);