import React from 'react';
import axios from 'axios';
import { handleResponse } from '../auth/handle-response';
import { url } from '../config/config'
import { Link } from 'react-router-dom';
import authenticationService from '../auth/AuthenticationService';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';


class DiagnoseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      items: null
    }
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    this.state.diagnoseId = params.id;
    this.setState({});
    await axios.get(`${url}clinic/diagnosedetail/${this.state.diagnoseId}`, {
      params: {
        'key': authenticationService.getAuthToken()
      }
    }).then(handleResponse)
      .then(res => {
        this.setState({
          data: res,
          items: res
        });
      });
  }


  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="container">
              <ol className="breadcrumb breadcrumb-arrow">
                <li><Link to={`/`}> Diagnose </Link> </li>
                <li><Link to={`/clinic/diagnosedetail/${this.state.diagnoseId}`}> Diagnose detail </Link> </li>
              </ol>
            </div>
          </div></div>
        <div className="container">
          <div className="row">

            <div >
              {this.state.data === null && <p>Loading Diagnose Detail...</p>}
              {this.state.data &&
                <div className="table-responsive">
                  <table className="table table-striped" >
                    <thead>
                        <tr>
                        <th>Composition</th>
                        <th>Medicine</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.data.map(item => (

                          <tr key={item.tranId} >

                              <td>{
                                item.composition.split('|').map((text, index) => (
                                  <React.Fragment key={`${text}-${index}`}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))
                              }</td>  
                              
                              <td>{
                                item.repeat.split('|').map((text, index) => (
                                  <React.Fragment key={`${text}-${index}`}>
                                    {text}
                                    <br />
                                  </React.Fragment>
                                ))
                              }</td> 
                                 
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
          <div className="buttons">
            <button className="btn btn-success" onClick={this.prepared.bind(this)}> Prepared</button>
            &nbsp; &nbsp;
        <button className="btn btn-success" onClick={this.print.bind(this)} >  Print  </button>
          </div>
        </div>
      </div>
    );
  }

  print() {
    console.log("Print");
  }

  async prepared() {
    console.log("Prepared");
    await axios.post(`${url}clinic/prepared/${this.state.diagnoseId}`, null, {
      params: {
        'key': authenticationService.getAuthToken()
      }
    }).then(handleResponse)
      .then(res => {
        console.log(res);
        if (res == null) {
          return;
        }
        store.addNotification({
          title: "Success!",
          message: "Status Update Success",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }, error => {
        store.addNotification({
          title: "Error!",
          message: error.response.data.error || "Error on Update Record",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 400,
            onScreen: true
          }
        });
      });
  }
}

export default DiagnoseDetail;